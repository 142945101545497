import React from "react";
import Layout from "../components/layout/layout";

export default function Shop() {
  return (
    <Layout>
      <h1>Coming Soon!!</h1>
    </Layout>
  );
}
